import React from "react";
import { Loading } from "@nextui-org/react";

function SuspenseLoader() {
  return (
    <Loading />
  );
}

export default SuspenseLoader;
